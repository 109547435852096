/**
 * Copyright © SoftServe, Inc. All rights reserved.
 *
 * @license proprietary (Non-free Software License)
 */
 
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * Copyright © SoftServe, Inc. All rights reserved.
 *
 * @license proprietary (Non-free Software License)
 */

.ProductPage {
    &-Wrapper {
        @include desktop {
            grid-template-columns: 7fr 5fr;
            padding-block-end: 0px;
        }

        @include mobile {
            grid-auto-flow: column
        }
    }

    .ProductLinks-Title {
        @include mobile {
            text-transform: none;
            margin-block-end: 40px;
        }
    }

    section .ContentWrapper {
        @include mobile {
            padding-block-start: 0px;
        }
    }

    @include mobile {
        padding-block-end: 90px;
    }
}

.pointer {
    position: absolute;
    z-index: 9;
}
