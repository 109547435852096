/**
 * Copyright © SoftServe, Inc. All rights reserved.
 *
 * @license proprietary (Non-free Software License)
 */
 
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * Copyright © SoftServe, Inc. All rights reserved.
 *
 * @license proprietary (Non-free Software License)
 */

.product-links-container {
    display: flex;

    .product-links-hr-line {
        background: rgba(34, 51, 106, 0.50);
        width: 100%;
        height: 1px;
        position: relative;
    }
}

.ProductLinks {
    &-Wrapper {
        @include  desktop {
            padding-block-end: 50px;
        }
    }

    .SlickSliderBlock .slick-list {
        padding-block-start: 25px;
    }

    &-Title {
        @include mobile {
            text-transform: uppercase;
            text-align: start;
            padding: 0;
            margin-block-end: 10px;
            font-size: 16px;
            line-height: 0px;
            margin: 0;
        }

        @include desktop {
            font-size: 30px;
            line-height: 0px;
            text-align: start;
            text-transform: inherit;
            min-width: 300px;
            padding: 0;
            margin: 0;
        }
    }

    &-List {
        margin-block-start: 50px;

        @include mobile {
            grid-template-columns: repeat(1, 1fr);
            grid-row-gap: 50px;
        }

        @include desktop {
            grid-template-columns: repeat(4, 1fr);
            grid-row-gap: 20px;
            grid-column-gap: 20px;
        }
    }
}

.related-products {
    h2 {
        font-size: 30px;
        position: relative;
        color: #22336A;

        @include mobile {
            font-size: 20px;
            margin: 0px;
            padding: 0px;
            width: 88%;
        }
    }

    h2 span {
        background-color: white;
        padding-inline-end: 34px;

        @include mobile {
            padding-inline-end: 10px;
        }
    }

    h2::after {
        content: "";
        position: absolute;
        inset-block-end: 0;
        inset-inline: 0;
        inset-inline-start: 0;
        height: 0.5em;
        border-top: 1px solid #9099B4;
        z-index: -1;
        inset-block-start: 50%;
    }
}


