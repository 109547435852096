/**
 * Copyright © SoftServe, Inc. All rights reserved.
 *
 * @license proprietary (Non-free Software License)
 */
 
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * Copyright © SoftServe, Inc. All rights reserved.
 *
 * @license proprietary (Non-free Software License)
 */

.ProductGallery {
    display: flex;
    flex-direction: row-reverse;
    height: 400px;
    margin: 0;

    @include narrow-tablet {
        height: 550px
    }

    @include desktop {
        height: auto;
        padding-block-end: 30px;
    }

    &-Additional {
        width: 120px;
        height: 100%;
        overflow: hidden;
    }

    .ProductGalleryThumbnailImage {
        @include desktop {
            padding: 0;
        }

        img {
            border-radius: 10px;
        }
    }

    &-SliderWrapper {
        @include desktop {
            margin-inline-start: 20px;
        }

        &_hasSingleImg {
            margin-inline-start: 0;
        }
    }
}
