/**
 * Copyright © SoftServe, Inc. All rights reserved.
 *
 * @license proprietary (Non-free Software License)
 */
 
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.ProductPrice {
    color: var(--secondary-blue-color);

    &-HighPrice {
        padding-inline-end: 10px;
        font-weight: 700;

        @include mobile {
            font-size: 12px;
            padding-inline-end: 0px;
        }

        @include mini-mobile {
            padding-inline-end: 0px;
            font-size: 10px;
        }
    }

    &-Price {
        text-decoration: none;
        display: inline-block;
        font-size: 22px;
    }
}
