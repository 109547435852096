/**
 * Copyright © SoftServe, Inc. All rights reserved.
 *
 * @license proprietary (Non-free Software License)
 */
 
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * Copyright © SoftServe, Inc. All rights reserved.
 *
 * @license proprietary (Non-free Software License)
 */

.CategoryProductList {
    margin-block-start: 30px;
    padding-block-end: 0px;
    inset-block-start: 0px;

    @include mobile {
        margin-block-start: 0px;
        padding-block-end: 60px;
    }

    .Pagination {
        display: none;
    }

    &-Page_layout_grid {
        grid-column-gap: 30px;
        grid-row-gap: 30px;
        grid-template-columns: repeat(1, 1fr);

        @include mobile {
            grid-column-gap: 10px;
            grid-row-gap: 10px;
            grid-template-columns: repeat(2, 1fr);
        }

        @include  narrow-tablet {
            grid-template-columns: repeat(3, 1fr);
        }

        @include tablet {
            grid-template-columns: repeat(3, 1fr);
        }

        @include narrow-desktop {
            grid-template-columns: repeat(3, 1fr);
        }

        @include ultra-narrow-desktop {
            /* stylelint-disable-next-line declaration-no-important */
            grid-template-columns: repeat(3, 1fr) !important;
        }

        @include desktop {
            grid-template-columns: repeat(4, 1fr);
        }
    }

    .CategoryProductList-Page_layout_grid ~ .CategoryProductList-Page {
        margin-block-start: 20px;
    }
}
